import { isNil } from 'lodash-es';
import { IApiIntelReportMapper } from '@pn/core/mappers/intel-report.ts';
import { IntelReport } from '@pn/core/domain/intel/intel-report.ts';
import { ApiIntelReport } from '@pn/services/api/intel-report/types.ts';

export const apiIntelReportMapper: IApiIntelReportMapper = {
  toDomainIntelReport: (apiIntelReport) => {
    if (isNil(apiIntelReport)) {
      throw new Error('apiIntelReport is not a valid object');
    }
    const intelReport: IntelReport = {
      id: apiIntelReport.uuid,
      emailSchedule: apiIntelReport.email_schedule,
      filters: {
        customStaticEndDate: apiIntelReport.custom_static_end_date,
        customStaticStartDate: apiIntelReport.custom_static_start_date,
        daysAgoWindow: apiIntelReport.days_ago_window,
        geoJSONGeometry: apiIntelReport.geojson_geometry,
        type: apiIntelReport.intel_report_type,
        name: apiIntelReport.name,
        customProductionMonth: apiIntelReport.production_month_to_consider,
        loadMostRecentProductionMonth: true,
        formation: apiIntelReport.formation,
        companyBrandId: apiIntelReport.company_brand_hashed_id,
        areaId: apiIntelReport.annotation_id,
        fieldName: apiIntelReport.field_name,
      },
      encodedFilters: apiIntelReport.encoded_url_parameters,
      isPinned: apiIntelReport.is_pinned ?? false,
      isArchived: apiIntelReport.is_archived,
    };
    return intelReport;
  },
  toTargetIntelReport(intelReport: IntelReport): ApiIntelReport {
    return {
      uuid: intelReport.id,
      name: intelReport.filters.name,
      email_schedule: intelReport.emailSchedule,
      custom_static_end_date: intelReport.filters.customStaticEndDate,
      custom_static_start_date: intelReport.filters.customStaticStartDate,
      days_ago_window: intelReport.filters.daysAgoWindow,
      geojson_geometry: intelReport.filters.geoJSONGeometry,
      intel_report_type: intelReport.filters.type,
      production_month_to_consider: intelReport.filters.customProductionMonth,
      formation: intelReport.filters.formation,
      company_brand_hashed_id: intelReport.filters.companyBrandId,
      annotation_id: intelReport.filters.areaId,
      encoded_url_parameters: intelReport.encodedFilters,
      field_name: intelReport.filters.fieldName,
      is_pinned: intelReport.isPinned,
      is_archived: intelReport.isArchived,
    };
  },
};
